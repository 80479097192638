import React, { useState } from 'react';
import queryString from 'query-string';
import Input from './common/Input';
import Button from './common/Button';
import PandoLogo from './assets/pando-logo.png';
import Background from './assets/background.png';

export default function LandingPage({ onSubmit }) {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showEmailPrompt, setShowEmailPrompt] = useState(false);

  function _onButtonClick() {
    const params = queryString.parse(window.location.search);
    const { db, unid, email } = params;

    if (!db) {
      onSubmit();
    } else {
      if (!unid && !email) {
        setShowEmailPrompt(true);
      } else {
        onSubmit(email);
      }
    }
  }

  function _onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      email.length > 0 &&
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      onSubmit(email);
    } else {
      setIsEmailValid(false);
    }
  }

  function _onChange(e) {
    setEmail(e.target.value);
    setIsEmailValid(true);
  }

  return (
    <div
      className='landing-page'
      style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
    >
      <div className='flex-columns'>
        <div className='logo-container'>
          <img src={PandoLogo} alt='logo' height={100} />
        </div>
        <div className='content'>
          {!showEmailPrompt && (
            <>
              <h1>Welcome to Pando</h1>
              <h3>A truly unique and interactive hybrid meeting experience!</h3>
              <p>Please take a moment to complete the system check process by clicking the button below.</p>
              <p>
                For the best Pando experience, please attempt to use the same device and internet connection you plan to use on the day of your show. If you are
                not able to do so now, please be sure to return to this system check to confirm compatibility prior to the event.
              </p>
              <div className='button-container'>
                <Button type='primary' text='Begin System Check' containerStyle={{ padding: '2px 40px', margin: 0 }} onClick={_onButtonClick} />
              </div>
            </>
          )}
          {showEmailPrompt && (
            <form onSubmit={_onSubmit}>
              <h1>Welcome to Pando</h1>
              <p>Enter the email address used when you registered for the Pando program</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 5,
                  width: 280,
                }}
              >
                <Input type='text' name='email' onChange={_onChange} value={email} style={{ width: 250, marginLeft: 0 }} hasError={!isEmailValid} />
              </div>
              <div className='button-container'>
                <Button type='primary' submit text='Submit' containerStyle={{ padding: '2px 40px', margin: 0 }} onClick={_onSubmit} />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
