import React from 'react';
import RTC from './rtc-common';
import { v4 as uuidv4 } from 'uuid';
import swal from '@sweetalert/with-react';
import SocketClient from './socket-client';
import CustomMessage from '../common/Messages/CustomMessage';
import NoConnectionIcon from '../assets/no-connection.svg';
import { isIpad } from './browser-util';

export default class RTCClient {
  async connect({ stream, name, location, uuid, availableDevices, supportedResolutions, isLoopbackTest }) {
    try {
      console.log('connect', { stream, name, location, uuid });

      this.uuid = uuid || uuidv4();
      const roomName = isLoopbackTest ? `loopback-${this.uuid}-client-send` : `${this.uuid}-client-send`;
      const rtc = new RTC(roomName, {
        name,
        location,
        availableDevices,
        supportedResolutions,
      });

      this.clientPeerConnection = await rtc.setup();
      this.clientRtc = rtc;
      this.stream = stream;

      if (this.clientPeerConnection.addStream) {
        this.clientPeerConnection.addStream(this.stream);
      } else {
        this.stream.getTracks().forEach((track) => {
          this.clientPeerConnection.addTrack(track);
        });
      }

      // Dynamically change resolution after having acquired the stream
      // const videoTrack = this.stream.getTracks().find(track => track.kind === 'video');
      // videoTrack.applyConstraints({
      //   width: { exact: width },
      //   height: { exact: height },
      // });
      //
      // const constraints = {
      //   audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      //   video: {deviceId: videoSource ? {exact: videoSource} : undefined}
      // };
      // References
      // https://github.com/webrtc/samples/blob/gh-pages/src/content/devices/input-output/js/main.js
      // https://blog.addpipe.com/common-getusermedia-errors/

      // Wait long enough for the backend to spin up it's RTC client
      await new Promise((resolve, reject) => {
        let attemptCount = 0;
        const timerId = setInterval(async () => {
          if (attemptCount++ < 5) {
            rtc.sendMessage({ type: 'state', value: 'CONFIG_COMPLETED' });

            console.log('Waiting for READY_FOR_OFFER');
            const { type, value } = await rtc.getMessage('state');
            if (type === 'state' && value === 'READY_FOR_OFFER') {
              clearInterval(timerId);
              resolve();
            }
          } else {
            clearInterval(timerId);
            reject('Unable to get a response from the RTC Server.');
            SocketClient.noConnectionAlertShowing = swal({
              buttons: {},
              closeOnClickOutside: false,
              closeOnEsc: false,
              className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
              content: (
                <CustomMessage
                  title='No Connection'
                  buttonLabel='Reconnect'
                  onClick={() => swal.close()}
                  body="Looks like we may be having some trouble setting up your WebRTC connection. Let's try again."
                  icon={NoConnectionIcon}
                />
              ),
            });
            await SocketClient.noConnectionAlertShowing;
            SocketClient.noConnectionAlertShowing = false;
            SocketClient.webRTCDisconnectedDeferred.resolve();
          }
        }, 1000);
      });

      rtc.startClient();

      this._startServer();
      // this._logVideoCodec();
      // this._logBitrate(this.clientPeerConnection, 'send', 'video');
    } catch (error) {
      console.log(error);
      this.cleanup();
      throw error;
    }
  }

  async _onMediaTrack({ track }) {
    console.log(`Received ${track.kind} MediaStreamTrack with ID ${track.id}`);
    if (track.kind === 'video') {
      const video = document.getElementById('remote-video');
      if (video) {
        video.muted = true; // Chrome will not auto-play unmuted video without user interaction
        video.autoplay = true;
        video.style.backgroundColor = '#181f26';
        const RTCStatsReport = await this.clientPeerConnection.getStats();
        for (const value of RTCStatsReport.values()) {
          const { type, kind, width, height } = value;
          if (type === 'media-source' && kind === 'video') {
            video.width = width;
            video.height = height;
          }
        }
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        video.srcObject = mediaStream;
      }
    } else if (track.kind === 'audio') {
      const audio = document.getElementById('remote-audio');
      if (audio) {
        audio.autoplay = true;
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        audio.srcObject = mediaStream;
      }
    }
  }

  async _startServer() {
    const rtc = new RTC(`${this.uuid}-client-recv`);
    const pc = await rtc.setup();

    pc.ontrack = this._onMediaTrack.bind(this);

    await new Promise(async (resolve) => {
      const { type, value } = await rtc.getMessage('state');
      if (type === 'state' && value === 'CONFIG_COMPLETED') {
        resolve();
      }
    });

    console.log('Sending READY_FOR_OFFER');
    rtc.sendMessage({
      type: 'state',
      value: 'READY_FOR_OFFER',
    });

    rtc.startServer();

    if (SocketClient.webRTCConnectionComplete) {
      SocketClient.webRTCConnectionComplete.resolve();
    }

    // this._logBitrate(rtc.socket, pc, 'recv', 'video');
    this.serverRtc = rtc;
  }

  // Resource: https://github.com/webrtc/samples/tree/gh-pages/src/content/peerconnection/bandwidth
  _logBitrate(pc, type, kind) {
    let comm;
    let lastResult;

    const reportType = type === 'send' ? 'outbound-rtp' : type === 'recv' ? 'inbound-rtp' : null;
    const bytesType = type === 'send' ? 'bytesSent' : type === 'recv' ? 'bytesReceived' : null;

    setInterval(async () => {
      if (!comm) {
        const comms = type === 'send' ? pc.getSenders() : type === 'recv' ? pc.getReceivers() : null;
        if (comms && comms.length > 0) {
          comm = comms.find((s) => s.track.kind === kind);
        }
        if (!comm) return;
      }
      const stats = await comm.getStats();
      stats.forEach((report) => {
        let bytes;
        if (report.type === reportType) {
          if (report.isRemote) {
            return;
          }
          const now = report.timestamp;
          bytes = report[bytesType];
          if (lastResult && lastResult.has(report.id)) {
            const bitrate = parseInt((8 * (bytes - lastResult.get(report.id)[bytesType])) / (now - lastResult.get(report.id).timestamp), 10);
            console.log(type, kind, '@', bitrate, 'Kbps');

            SocketClient.emit('out-bitrate-report', bitrate / 1000);
          }
        }
      });
      lastResult = stats;
    }, 1000);
  }

  async _logVideoCodec() {
    const codecs = [];
    let videoCodecId = null;

    const stats = await this.clientPeerConnection.getStats();
    const iterator = stats.entries();
    let next = iterator.next();
    while (next && !next.done) {
      const value = next.value[1];
      if (value.type === 'codec') {
        codecs.push(value);
      }
      if (value.codecId && value.mediaType === 'video') {
        videoCodecId = value.codecId;
      }
      next = iterator.next();
    }
    console.log(codecs.find((c) => c.id === videoCodecId));
  }

  disconnect() {
    this.clientRtc && this.clientRtc.stop();
    this.serverRtc && this.serverRtc.stop();
  }

  cleanup() {
    // We are using the stream and tracks on the local audio and video so do not stop them
    // if (this.stream) {
    //   this.stream.getTracks().forEach((track) => track.stop());
    // }
  }

  async switchTracks(type, newTrack) {
    try {
      const senders = this.clientPeerConnection.getSenders();
      if (senders) {
        for (const sender of senders) {
          if (sender.track && sender.track.kind === type) {
            await sender.replaceTrack(newTrack);
            return true;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}
