import React from 'react';
import SuccessIcon from '../assets/system-check-icons/icon-check-completed.svg';

export default function SuccessMessage() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#1E2A36',
          padding: '50px 30px',
          width: 400,
        }}
      >
        <img src={SuccessIcon} alt={''} width={160} />
        <h4 style={{ color: '#45B77A', marginTop: 10 }}>System Check Completed</h4>
        <div style={{ textAlign: 'center', fontSize: 14 }}>
          Thank you for completing the Pando System Check.
          <br />
          <br />
          If you plan to connect to Pando via a different device or network, please run the system check again. <br />
          <br />
          You may now close this window.
        </div>
      </div>
    </div>
  );
}
