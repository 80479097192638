import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Button.css';

const Button = (
  { type, onClick, onMouseDown, onMouseUp, text, align, icon, iconStyle, containerStyle, submit, disabled, children, tooltip, iconType = 'svg' },
  ref,
) => {
  const buttonClasses = ['button'];

  switch (type) {
    case 'primary':
      buttonClasses.push('primary');
      break;

    case 'secondary':
      buttonClasses.push('secondary');
      break;

    case 'danger':
      buttonClasses.push('danger');
      break;

    case 'link':
      buttonClasses.push('link');
      break;

    case 'danger-alt':
      buttonClasses.push('danger-alt');
      break;

    case 'waiting':
      buttonClasses.push('waiting');
      break;

    case 'lightBlue':
      buttonClasses.push('light-blue');
      break;

    default:
      buttonClasses.push('primary');
      break;
  }

  let iconImage = null;
  if (icon && iconType === 'svg') {
    iconImage = <img className='icon' src={icon} alt='button icon' style={iconStyle} />;
  } else if (icon && iconType === 'FontAwesome') {
    iconImage = <FontAwesomeIcon icon={icon} color='#FFFFFF' style={iconStyle} />;
  }

  return (
    <button
      ref={ref}
      type={submit ? 'submit' : 'button'}
      className={buttonClasses.join(' ')}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      tabIndex='-1'
      style={containerStyle}
      disabled={disabled}
    >
      <>
        <div className='button-content'>
          {iconImage}
          <div className='text' style={{ textAlign: align || 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {text || children}
          </div>
        </div>
        {tooltip ? <span className='tooltip'>{tooltip}</span> : null}
      </>
    </button>
  );
};

export default forwardRef(Button);
