export function isSafari() {
  const isAppleVendor = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;
  const isSafari = navigator.userAgent.indexOf('Safari') > -1; // Chrome returns Safari too but its not Apple vendor
  return isAppleVendor && isSafari;
}

export const isIpad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad';

export function reloadBrowser() {
  window.location.reload();
}

export function getMessageBoxTransform(height) {
  let scale = 1;
  if (height < 768 && height >= 630) {
    scale = 0.8;
  } else if (height < 630 && height >= 600) {
    scale = 0.7;
  } else if (height < 600 && height >= 550) {
    scale = 0.6;
  } else if (height < 550) {
    scale = 0.5;
  }
  return { transform: `scale(${scale})` };
}
