import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import SocketClient from './utils/socket-client';
import Onboarding from './onboarding';
import LandingPage from './LandingPage';
import FirewallTest from './FirewallTest';
import './css/App.css';

const { REACT_APP_SOCKET_SERVER_HOST } = process.env;

class App extends React.Component {
  state = {
    beginSystemCheck: null,
    email: null,
  };

  _onSubmit = async (email) => {
    const uuid = uuidv4();
    await SocketClient.setup(REACT_APP_SOCKET_SERVER_HOST, uuid);

    sessionStorage.setItem('uuid', uuid);

    this.setState({ beginSystemCheck: true, email });
  };

  render() {
    const { beginSystemCheck, email } = this.state;

    const { pathname } = window.location;
    if (pathname === '/firewall-test') {
      return <FirewallTest />;
    }

    return (
      <div className='App'>
        {!beginSystemCheck && <LandingPage onSubmit={this._onSubmit} />}
        {beginSystemCheck && <Onboarding email={email} />}
      </div>
    );
  }
}

export default App;
