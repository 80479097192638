import React, { useEffect, useState } from 'react';
import FirewallTest from '../FirewallTest';
import TimeUtil from '../utils/time-util';

export default function FirewallCheck({ title, onComplete, onFailure, onUpdateProgress }) {
  const [passed, setPassed] = useState(null);

  const _onSpeedTestSuccess = (results) => {
    const tested = results.filter((result) => result.success !== null);
    const firewallTests = tested.map((result) => ({ url: result.url, bandwidthMbps: result.bandwidth }));
    onUpdateProgress({ firewallTests });
    if (tested.length === results.length) {
      let passedCount = 0;
      let hasTwilioPass = false;
      results.forEach((result) => {
        if (result.bandwidth !== 0) {
          passedCount++;
          if (result.url.includes('twilio')) {
            hasTwilioPass = true;
          }
        }
      });
      setPassed(passedCount >= 2 && hasTwilioPass);
    }
  };

  useEffect(() => {
    (async () => {
      await TimeUtil.sleep(3000);
      if (passed === true) {
        onComplete && onComplete();
      } else if (passed === false) {
        onFailure && onFailure();
      }
    })();
  }, [passed]);

  return (
    <div style={{ width: '100%' }}>
      <h3>{title}</h3>
      <div>
        <table cellSpacing={3} style={{ width: '100%' }}>
          <tbody>
            <tr style={{ backgroundColor: 'rgb( 31, 43, 55)' }}>
              <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                <FirewallTest embedded={true} speedTests={['0.25mb']} onComplete={_onSpeedTestSuccess} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
