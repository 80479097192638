import React, { useEffect, useState, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import MediaDeviceUtil from '../utils/media-device-util';
import PromptIcon from '../assets/system-check-icons/grant-permission.svg';
import DeniedIcon from '../assets/system-check-icons/icon-access-denied.svg';
import GrantedIcon from '../assets/system-check-icons/permision-granted.svg';
import anim from '../assets/animations/browser_permissions.json';
import TimeUtil from '../utils/time-util';

const PermissionsStatus = ({ icon, title, message }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0 20px' }}>
      <img src={icon} height={120} alt='' />
      <div style={{ marginLeft: 20 }}>
        <h2>{title}</h2>
        {message && <p style={{ color: '#999', fontSize: 13 }}>{message}</p>}
      </div>
    </div>
  );
};

export default function BrowserPermissionsCheck({ title, onComplete, onUpdateProgress }) {
  const _timerId = useRef(null);
  const [validPermissions, setValidPermissions] = useState(null);
  const [permissionsStatus, setPermissionsStatus] = useState(null);
  const [showError, setShowError] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);

  const _requestPermissions = async () => {
    const { permissions } = window.navigator;
    let _validPermissions = false;

    if (permissions) {
      const results = await Promise.all([permissions.query({ name: 'camera' }), permissions.query({ name: 'microphone' })]);

      const cameraPermission = results[0];
      const microphonePermission = results[1];
      if (cameraPermission.state === 'granted' && microphonePermission.state === 'granted') {
        clearInterval(_timerId.current);
        _validPermissions = true;
      } else if (cameraPermission.state === 'denied' || microphonePermission.state === 'denied') {
        setPermissionsStatus('denied');
        _validPermissions = false;
      } else {
        setPermissionsStatus('prompt');
        const stream = await MediaDeviceUtil.getUserMedia({ audio: true, video: true }, true);
        if (stream) {
          clearInterval(_timerId.current);
          _validPermissions = true;
        } else {
          setPermissionsStatus('denied');
          _validPermissions = false;
        }
      }
    } else {
      const stream = await MediaDeviceUtil.getUserMedia({ audio: true, video: true }, true);
      if (stream) {
        clearInterval(_timerId.current);
        _validPermissions = true;
      } else {
        setPermissionsStatus('denied');
        _validPermissions = false;
      }
    }

    if (_validPermissions) {
      const devices = await MediaDeviceUtil.getAvailableDevices();
      const videoInputs = devices.filter((device) => device.kind === 'videoinput');
      const audioInputs = devices.filter((device) => device.kind === 'audioinput');
      _validPermissions = videoInputs.length > 0 && audioInputs.length > 0;
      if (!_validPermissions) {
        setPermissionsStatus('device-not-found');
      }
    }

    setValidPermissions(_validPermissions);
  };

  useEffect(() => {
    _requestPermissions();
    _timerId.current = setInterval(_requestPermissions, 1000);
    return () => {
      clearInterval(_timerId.current);
    };
  }, []);

  useEffect(() => {
    if (validPermissions === true && animationEnded) {
      (async () => {
        setShowError(false);
        setAnimationEnded(true);
        onUpdateProgress({ hasCamMicAccess: true });
        await TimeUtil.sleep(3000);
        onComplete && onComplete();
      })();
    }
  }, [validPermissions]);

  let _icon = null;
  let _title = null;
  let _message = null;

  if (permissionsStatus === 'prompt') {
    _icon = PromptIcon;
    _title = 'Grant Permission';
    _message = 'Please grant access to your mic and webcam.';
  } else if (permissionsStatus === 'denied') {
    _icon = DeniedIcon;
    _title = 'Access Denied';
    _message = 'Enable permissions for microphone and camera on your designated web browser. This can be done using the icons on your URL toolbar.';
  } else if (permissionsStatus === 'device-not-found') {
    _icon = DeniedIcon;
    _title = 'Device Not Found';
    _message = 'Please connect a microphone and camera to your device and refresh the page.';
  }

  const _onAnimationEvent = async (event) => {
    if (event === 'complete') {
      setAnimationEnded(true);
      if (validPermissions === true) {
        onUpdateProgress({ hasCamMicAccess: true });
        await TimeUtil.sleep(3000);
        onComplete && onComplete();
      } else {
        onUpdateProgress({ hasCamMicAccess: false });
        setShowError(true);
        onComplete && onComplete(false);
      }
    }
  };

  return (
    <div style={{ width: '100%', height: 'calc(100% - 45px)' }}>
      <h3>{animationEnded && validPermissions ? 'Camera & Mic Accessible' : title}</h3>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        {!showError && (
          <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            <Player
              autoplay
              loop={false}
              speed={1.5}
              onEvent={_onAnimationEvent}
              src={anim}
              style={{ height: '160px', width: '160px', opacity: !animationEnded ? 1 : 0, marginBottom: 55 }}
            />
            <div
              className='fade-in'
              style={{
                width: 310,
                position: 'absolute',
                zIndex: 9,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: animationEnded && validPermissions ? 1 : 0,
                marginBottom: 55,
              }}
            >
              <img src={GrantedIcon} alt='permissions' width={160} />
            </div>
          </div>
        )}
        {showError && (
          <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            <table cellSpacing={3} style={{ width: '100%' }}>
              <tbody>
                <tr style={{ backgroundColor: permissionsStatus === 'prompt' ? 'rgb(45, 64, 81)' : 'rgb( 31, 43, 55)' }}>
                  <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                    <PermissionsStatus icon={_icon} title={_title} message={_message} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
