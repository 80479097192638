import React, { Component } from 'react';
import pandoLogo from './assets/pando-logo.png';
import twilioLogo from './assets/twilio-logo.png';
import SocketClient from './utils/socket-client';
import { v4 as uuidv4 } from 'uuid';
import { get } from './services/api';
import RTC from './utils/rtc-common';
import GreenCheckIcon from './assets/icon-check-circle.svg';
import ErrorCircleIcon from './assets/icon-error-circle.svg';
import Spinner from './common/Spinner';

const { REACT_APP_API_KEY, REACT_APP_SOCKET_SERVER_HOST } = process.env;

export default class FirewallTest extends Component {
  SPEED_TESTS = ['1mb'];

  _dataChannel = null;
  _speedTestStartTime = null;
  _speedTestIndex = 0;
  _bytesReceived = 0;
  _totalBytesReceived = 0;

  state = {
    speedTestSuccess: null,
    bandwidth: 0,
    startTimestamp: Date.now(),
    results: [],
    ipInfo: null,
  };

  async componentDidMount() {
    try {
      const { speedTests } = this.props;
      if (speedTests) {
        this.SPEED_TESTS = speedTests;
      }
      this._iceServersConfig = (await get(`/iceServers?apiKey=${REACT_APP_API_KEY}`)).data;
      const ipInfo = (await get(`/ipInfo?apiKey=${REACT_APP_API_KEY}`)).data;

      const tests = [];
      const results = [];

      this._iceServersConfig.forEach((config) => {
        const { urls } = config;
        urls.forEach((url) => {
          tests.push({
            credential: config.credential,
            username: config.username,
            password: config.password,
            ttl: config.ttl,
            urls: [url],
            uris: [url],
          });
          results.push({
            url,
            bandwidth: 0,
            success: null,
          });
        });
      });
      this.setState({ results, ipInfo });

      this._runSpeedTests(tests);
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    this._close();
  }

  componentDidUpdate(_, prevState) {
    const { speedTestSuccess } = this.state;

    if (speedTestSuccess !== prevState.speedTestSuccess) {
      this._onComplete();
    }
  }

  _runSpeedTests = async (tests) => {
    const uuid = uuidv4();
    const roomName = `${uuid}-client-recv`;
    await SocketClient.setup(REACT_APP_SOCKET_SERVER_HOST, uuid);

    for (let i = 0; i < tests.length; i++) {
      this._testIndex = i;
      const test = tests[i];

      SocketClient.emit('run-speed-test', { uuid });

      this._rtc = await this._start(roomName, test);
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.state.speedTestSuccess !== null) {
            this.setState({ speedTestSuccess: null });
            clearInterval(interval);
            resolve();
          }
        }, 1000);
      });
    }
  };

  async _start(roomName, iceServer) {
    const rtc = new RTC(roomName, null, [iceServer]);
    const pc = await rtc.setup();

    this._speedTestIndex = 0;
    this._totalBytesReceived = 0;
    this._setupSpeedTestHooks(pc);

    await new Promise(async (resolve) => {
      const { type, value } = await rtc.getMessage('state');
      if (type === 'state' && value === 'CONFIG_COMPLETED') {
        resolve();
      }
    });

    rtc.sendMessage({
      type: 'state',
      value: 'READY_FOR_OFFER',
    });

    await rtc.startServer();
    return rtc;
  }

  _setupSpeedTestHooks = (pc) => {
    const _failCurrentSpeedTest = () => {
      const { results } = this.state;
      results[this._testIndex].success = false;
      this.setState({ speedTestSuccess: false, results });
    };

    const timerId = setTimeout(() => {
      if (pc.connectionState !== 'connected') {
        _failCurrentSpeedTest();
      }
    }, 5000);

    pc.onconnectionstatechange = () => {
      const { connectionState } = pc;
      // console.log('connectionState', this.state.results[this._testIndex].url, connectionState);

      if (connectionState === 'connected') {
        clearTimeout(timerId);
        // this._rtc._logConnectionDetails();
      } else if (connectionState === 'disconnected' || connectionState === 'failed') {
        _failCurrentSpeedTest();
      }
    };

    pc.addEventListener('datachannel', this._onDataChannel);
    // NOTE(mroberts): This is a hack so that we can get a callback when the
    // RTCPeerConnection is closed. In the future, we can subscribe to
    // "connectionstatechange" events.
    const { close } = pc;
    pc.close = function () {
      if (this._dataChannel) {
        this._dataChannel.removeEventListener('message', this._onMessage);
        this._dataChannel.removeEventListener('open', this._startSpeedTest);
      }
      return close.apply(this, arguments);
    };
  };

  _onDataChannel = ({ channel }) => {
    if (channel.label !== 'speed-test') {
      return;
    }
    this._speedTestStartTime = Date.now();
    this._dataChannel = channel;
    this._dataChannel.addEventListener('message', this._onMessage);
    this._dataChannel.addEventListener('open', this._startSpeedTest);
  };

  _onMessage = ({ data }) => {
    const bytesExpected = this.SPEED_TESTS[this._speedTestIndex].replace('mb', '') * 1024 * 1024;
    const duration = Date.now() - this._speedTestStartTime;
    const mbps = (8 * this._totalBytesReceived) / (1024 * 1024) / (duration / 1000);
    const bandwidth = mbps.toFixed(1);

    if (data === 'done') {
      const { results } = this.state;
      results[this._testIndex].bandwidth = bandwidth;

      if (this._bytesReceived >= bytesExpected) {
        this._bytesReceived = 0;
        if (++this._speedTestIndex < this.SPEED_TESTS.length) {
          this._startSpeedTest();
        } else {
          results[this._testIndex].success = true;
          this.setState({ speedTestSuccess: true, results });
        }
      } else {
        results[this._testIndex].success = false;
        this.setState({
          speedTestSuccess: false,
          results,
        });
      }
    } else {
      this._bytesReceived += data.length;
      this._totalBytesReceived += data.length;
    }
  };

  _startSpeedTest = () => {
    this._dataChannel.send(`run-speed-test-${this.SPEED_TESTS[this._speedTestIndex]}`);
  };

  _close() {
    if (this._rtc && this._rtc.pc) {
      this._rtc.stop();
      this._rtc = null;
    }
  }

  _onComplete = () => {
    this._close();
    if (this.props.onComplete) {
      this.props.onComplete(this.state.results);
    }
  };

  render() {
    const { embedded } = this.props;

    if (embedded) {
      const styles = {
        container: {
          display: 'flex',
          flexDirection: 'column',
        },
        resultsContainer: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
          marginBottom: 40,
        },
        checkmarksContainer: {
          display: 'flex',
          flexDirection: 'row',
          height: 48,
          width: 400,
        },
        checkmark: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 100,
        },
        checkmarkText: {
          fontSize: 12,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
      };

      const completedTests = this.state.results.filter((r) => r.success !== null);

      return (
        <div style={styles.container}>
          <div style={{ ...styles.resultsContainer, marginTop: 30 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={twilioLogo} alt={''} height={37} style={{ position: 'relative', marginRight: 10, opacity: 0.8 }} />
              <div style={styles.checkmarksContainer}>
                {completedTests.slice(0, 4).map((result, index) => {
                  const urlParts = result.url.split(':');
                  const port = urlParts[urlParts.length - 1].split('?')[0] || '3478';
                  const protocol = result.url.split('=')[1] || 'udp';
                  const isRelay = result.url.startsWith('turn:');
                  return (
                    <div key={`results1-${index}`} style={styles.checkmark}>
                      {result.bandwidth > 0 && (
                        <img src={result.success ? GreenCheckIcon : ErrorCircleIcon} alt={''} width={22} style={{ position: 'relative', top: 3 }} />
                      )}
                      {result.bandwidth === 0 && result.success === false && (
                        <img src={ErrorCircleIcon} alt={''} width={22} style={{ position: 'relative', top: 3 }} />
                      )}
                      {result.success !== null && (
                        <div>
                          {protocol.toUpperCase()}
                          {isRelay && <span style={{ color: 'rgb( 255, 255, 255, 0.5)' }}> (Relay)</span>}
                        </div>
                      )}
                    </div>
                  );
                })}
                {completedTests.length < 4 && (
                  <div style={{ ...styles.checkmark }}>
                    <Spinner style={{ transform: 'scale(0.6)' }} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={styles.resultsContainer}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={pandoLogo} alt={''} height={45} style={{ position: 'relative', marginRight: 10 }} />
              <div style={styles.checkmarksContainer}>
                {completedTests.slice(4, 8).map((result, index) => {
                  const urlParts = result.url.split(':');
                  const port = urlParts[urlParts.length - 1].split('?')[0] || '3478';
                  const protocol = result.url.split('=')[1] || 'udp';
                  const isRelay = result.url.startsWith('turn:');
                  return (
                    <div key={`results2-${index}`} style={styles.checkmark}>
                      {result.bandwidth > 0 && (
                        <img src={result.success ? GreenCheckIcon : ErrorCircleIcon} alt={''} width={22} style={{ position: 'relative', top: 3 }} />
                      )}
                      {result.bandwidth === 0 && result.success === false && (
                        <img src={ErrorCircleIcon} alt={''} width={22} style={{ position: 'relative', top: 3 }} />
                      )}
                      {result.success !== null && (
                        <div>
                          {protocol.toUpperCase()}
                          {isRelay && <span style={{ color: 'rgb( 255, 255, 255, 0.5)' }}> (Relay)</span>}
                        </div>
                      )}
                    </div>
                  );
                })}
                {completedTests.length >= 4 && completedTests.length < 8 && (
                  <div style={{ ...styles.checkmark }}>
                    <Spinner style={{ transform: 'scale(0.6)' }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    const { ipInfo } = this.state;

    return (
      <div className='App' style={{ color: 'white' }}>
        {ipInfo && (
          <table style={{ width: 768, marginBottom: 15 }}>
            <tbody>
              <tr>
                <th style={{ verticalAlign: 'top', width: 120, textAlign: 'left' }}>Public IP</th>
                <td style={{ verticalAlign: 'top' }}>{ipInfo.publicIp}</td>
              </tr>
              <tr>
                <th style={{ verticalAlign: 'top', width: 120, textAlign: 'left' }}>DNS Name</th>
                <td style={{ verticalAlign: 'top' }}>{ipInfo.dnsName}</td>
              </tr>
              <tr>
                <th style={{ verticalAlign: 'top', width: 120, textAlign: 'left' }}>Privacy</th>
                <td style={{ verticalAlign: 'top' }}>{ipInfo.privacy}</td>
              </tr>
            </tbody>
          </table>
        )}
        <table cellSpacing={3}>
          <thead>
            <tr style={{ backgroundColor: 'rgb(45, 64, 81)', height: 35 }}>
              <th>URL</th>
              <th>Protocol</th>
              <th>Port</th>
              <th>Bandwidth</th>
              <th>Success</th>
            </tr>
          </thead>
          <tbody>
            {this.state.results.map((result, index) => {
              const urlParts = result.url.split(':');
              const port = urlParts[urlParts.length - 1].split('?')[0] || '3478';
              const protocol = result.url.split('=')[1] || 'udp';
              return (
                <tr key={index} style={{ backgroundColor: 'rgb( 31, 43, 55)', height: 35 }}>
                  <td style={{ paddingLeft: 10, width: 250 }}>{`${urlParts[0]}:${urlParts[1]}`}</td>
                  <td align='center' style={{ width: 120 }}>
                    {protocol.toUpperCase()}
                  </td>
                  <td align='center' style={{ width: 120 }}>
                    {port}
                  </td>
                  <td align='center' style={{ width: 120 }}>
                    {result.bandwidth > 0 ? `${result.bandwidth} Mbps` : '-'}
                  </td>
                  <td align='center' style={{ width: 120 }} valign='middle'>
                    {result.bandwidth > 0 && (
                      <img src={result.success ? GreenCheckIcon : ErrorCircleIcon} alt={''} width={22} style={{ position: 'relative', top: 3 }} />
                    )}
                    {result.bandwidth === 0 && result.success === false && (
                      <img src={ErrorCircleIcon} alt={''} width={22} style={{ position: 'relative', top: 3 }} />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
