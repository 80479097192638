import React, { useState } from 'react';
import queryString from 'query-string';
import { put } from '../services/api';
import PandoLogo from '../assets/pando-logo.png';
import useStepNavigation from '../hooks/useStepNavigation';
import StepsNav from './StepsNav';
import BrowserCheck from './BrowserCheck';
import BrowserPermissionsCheck from './BrowserPermissionsCheck';
import VideoCheck from './VideoCheck';
import AudioCheck from './AudioCheck';
import FirewallCheck from './FirewallCheck';
import BandwidthTest from './BandwidthTest';
import SuccessMessage from './SuccessMessage';
import FailureMessage from './FailureMessage';
import './Onboarding.css';

const { REACT_APP_API_KEY } = process.env;

const OnboardingComponents = [
  {
    title: 'Checking your Browser',
    component: (props) => <BrowserCheck {...props} />,
  },
  {
    title: 'Verifying Camera & Mic Access',
    component: (props) => <BrowserPermissionsCheck {...props} />,
  },
  {
    title: 'Checking your Firewall',
    component: (props) => <FirewallCheck {...props} />,
  },
  {
    title: 'Checking your Connection Speed',
    component: (props) => <BandwidthTest {...props} />,
  },
  {
    title: 'Check your Camera',
    component: (props) => <VideoCheck {...props} />,
  },
  {
    title: 'Check your Microphone & Speakers',
    component: (props) => <AudioCheck {...props} />,
  },
];

export default function Onboarding(props) {
  const { steps, currentStep, setCurrentStep } = useStepNavigation(OnboardingComponents.length);
  const [systemCheckPassed, setSystemCheckPassed] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  const _updateProgress = async (data) => {
    try {
      const params = queryString.parse(window.location.search);
      const { db, unid } = params;
      if (!db) return;

      const uuid = sessionStorage.getItem('uuid');
      await put(`/system-check?apiKey=${REACT_APP_API_KEY}`, { ...data, db, email: props.email || params.email, unid, uuid });
    } catch (error) {
      console.error(error);
    }
  };

  const _currentComponent = OnboardingComponents[currentStep];
  let _content = _currentComponent.component({
    title: _currentComponent.title,
    onComplete: (valid) => {
      if (typeof valid === 'undefined') {
        setCurrentStep(currentStep + 1);
      }
    },
    onSuccess: () => {
      setSystemCheckPassed(1);
    },
    onFailure: (err) => {
      setErrorMessage(err);
      setSystemCheckPassed(-1);
    },
    onUpdateProgress: _updateProgress,
  });

  if (systemCheckPassed === 1) {
    _content = <SuccessMessage />;
  } else if (systemCheckPassed === -1) {
    _content = (
      <FailureMessage
        message={errorMessage}
        onRetry={() => {
          setCurrentStep(null);
          setCurrentStep(currentStep);
          setSystemCheckPassed(0);
        }}
      />
    );
  }

  return (
    <div className='onboarding-container'>
      <div className='onboarding'>
        <div className='onboarding-title'>
          <img src={PandoLogo} alt='Pando Logo' width={135} />
        </div>
        <div className='onboarding-content'>{_content}</div>
        <div className='onboarding-footer'>{systemCheckPassed === 0 && <StepsNav steps={steps} currentStep={currentStep} onClick={setCurrentStep} />}</div>
      </div>
    </div>
  );
}
