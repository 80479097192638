import React from 'react';
import Logo from '../../assets/pando-logo.png';
import './Messages.css';

function CustomMessage({ title, body, buttonLabel, icon, onClick, vertical, footerElement }) {
  return (
    <div className={vertical ? 'vertical-message-box' : 'horizontal-message-box'}>
      <div className='header'>
        <img src={Logo} alt='Pando Branches' />
      </div>
      <div className='content'>
        <div className='left'>
          <div className='text'>
            <div>{title}</div>
            <div
              style={{
                fontSize: 14,
                color: '#797878',
                marginTop: 15,
                marginBottom: 5,
                textAlign: 'center',
              }}
            >
              {body}
            </div>
          </div>
          {buttonLabel && <button onClick={onClick}>{buttonLabel}</button>}
        </div>
        {icon && (
          <div className='right'>
            <img src={icon} alt='icon' />
          </div>
        )}
      </div>
      {footerElement && <div style={{ color: '#fff', marginTop: 20, whiteSpace: 'pre-line', fontSize: 14 }}>{footerElement}</div>}
    </div>
  );
}

export default CustomMessage;
