import React from 'react';
import SocketClient from './socket-client';
import swal from '@sweetalert/with-react';
import CustomMessage from '../common/Messages/CustomMessage';
import NoConnectionIcon from '../assets/no-connection.svg';
import { isIpad } from './browser-util';
import { get } from '../services/api';
import Storage from './storage';
import EventEmitter from './event-emitter';

const localStorage = Storage.getLocalStorage();
const { maybePreferCodec, preferBitRate } = require('./sdputils');

const { REACT_APP_API_KEY } = process.env;

const USE_H264 = true;
const MAX_VIDEO_BITRATE = 1000;
const MAX_AUDIO_BITRATE = 50;

export default class RTCCommon {
  constructor(roomName, metadata, iceServers) {
    this.roomName = roomName;
    this.iceServers = iceServers;

    SocketClient.joinRoom(roomName);

    if (metadata) {
      SocketClient.emit('add-metadata', metadata);
    }

    this.getMessage = this.getMessage.bind(this);
    this.getAnswer = this.getAnswer.bind(this);
    this.getOffer = this.getOffer.bind(this);
    this.onCandidate = this.onCandidate.bind(this);
    this.sendCandidate = this.sendCandidate.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.setup = this.setup.bind(this);
    this.stop = this.stop.bind(this);

    if (iceServers && iceServers.length === 1 && iceServers[0].uris.length === 1) {
      this.iceTransportPolicy = iceServers[0].uris[0].startsWith('turn:') ? 'relay' : 'all';
      if (iceServers[0].uris[0].includes('?transport=') !== -1) {
        this.strictProtocol = iceServers[0].uris[0].split('?transport=')[1];
      }
    }
  }

  async setup() {
    let iceServers;

    if (this.iceServers) {
      iceServers = this.iceServers;
    } else {
      iceServers = (await get(`/iceServers?apiKey=${REACT_APP_API_KEY}`)).data;
    }

    console.debug('Creating RTCPeerConnection');
    this.pc = new RTCPeerConnection({
      bundlePolicy: 'max-bundle',
      rtcpMuxPolicy: 'require',
      iceTransportPolicy: this.iceTransportPolicy || 'all',
      iceServers,
    });

    this.pc.onconnectionstatechange = async () => {
      const { connectionState } = this.pc;
      if (connectionState === 'connected') {
        // this._logConnectionDetails();
      }
      // failed can come in a bit after disconnected and we may have already established a new socket connection
      // Seems like iPad connections are being closed but reported as disconnected, thus using the roomName
      // Also using the closing flag to avoid a reconnect message when the client explicitly closes the connection ie. entering into breakout rooms
      else if (
        SocketClient.socket.connected &&
        SocketClient.socket.id === this.socketId &&
        (connectionState === 'disconnected' || connectionState === 'failed') &&
        !this.closing
      ) {
        console.log('WebRTC connection issue', connectionState, this.socketId, SocketClient.socket.id);
        SocketClient.noConnectionAlertShowing = swal({
          buttons: {},
          closeOnClickOutside: false,
          closeOnEsc: false,
          className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
          content: (
            <CustomMessage
              title='No Connection'
              buttonLabel='Reconnect'
              onClick={() => swal.close()}
              body="Looks like we may be having some trouble connecting to the server. Let's try reconnecting."
              icon={NoConnectionIcon}
            />
          ),
        });
        await SocketClient.noConnectionAlertShowing;
        SocketClient.noConnectionAlertShowing = false;
        SocketClient.webRTCDisconnectedDeferred.resolve();
      }
    };

    this.pc.onicecandidate = ({ candidate }) => {
      if (candidate) {
        console.debug('Sending ICE candidate');
        this.sendCandidate(candidate);
      }
    };

    this.queuedCandidates = [];
    this.onCandidate(async (candidate) => {
      if (!this.strictProtocol || candidate.type !== 'host' || candidate.protocol === this.strictProtocol) {
        if (!this.pc.remoteDescription) {
          this.queuedCandidates.push(candidate);
          return;
        }
        console.debug('Adding ICE candidate');
        await this.pc.addIceCandidate(candidate);
        console.debug('Added ICE candidate');
      }
    });

    return this.pc;
  }

  _logConnectionDetails = async () => {
    try {
      const RTCStatsReport = await this.pc.getStats();
      const ignoredTypes = ['certificate', 'stream', 'transport', 'peer-connection', 'remote-inbound-rtp'];

      const data = {};
      let localCandidateId;
      let remoteCandidateId;

      while (this.pc.connectionState === 'connected' && (!localCandidateId || !remoteCandidateId)) {
        for (const value of RTCStatsReport.values()) {
          const { type, ip, address, protocol, port, url } = value;
          if (!ignoredTypes.includes(type)) {
            if (type === 'candidate-pair' && value.state === 'succeeded') {
              localCandidateId = value.localCandidateId;
              remoteCandidateId = value.remoteCandidateId;
            } else if (type === 'remote-candidate' && value.id === remoteCandidateId) {
              data[type] = { type: this.rtcType, port, url, ip: ip || address, protocol };
            } else if (type === 'local-candidate' && value.id === localCandidateId) {
              data[type] = { type: this.rtcType, port, url, ip: ip || address, protocol };
            }
          }
        }

        if (this.pc.connectionState === 'connected' && (!localCandidateId || !remoteCandidateId)) {
          await new Promise((resolve) => setTimeout(resolve, 250));
        }
      }

      console.log('Connection details', data);
    } catch (error) {
      console.log(error);
    }
  };

  async startClient() {
    this.rtcType = 'client';
    this.socketId = SocketClient.socket.id;

    console.debug('Creating offer');
    const offer = await this.pc.createOffer({ offerToReceiveVideo: true });

    console.debug('Created offer; setting local description');
    await this.pc.setLocalDescription(offer);

    if (USE_H264) {
      offer.sdp = maybePreferCodec(offer.sdp, 'video', 'send', 'H264');
      // offer.sdp = maybePreferCodec(offer.sdp, 'video', 'receive', 'VP9');
    }

    // This doens't seem to work. Tested on Chrome. Leaving here in case it works on other browsers.
    offer.sdp = preferBitRate(offer.sdp, MAX_VIDEO_BITRATE, 'video');
    offer.sdp = preferBitRate(offer.sdp, MAX_AUDIO_BITRATE, 'audio');

    // This does work but may have browser limitations
    this._setBitrate(MAX_VIDEO_BITRATE, 'video');
    this._setBitrate(MAX_AUDIO_BITRATE, 'audio');

    console.debug('Set local description; sending offer');
    this.sendMessage(offer);

    console.debug('Waiting for answer');
    const answer = await this.getAnswer();

    console.debug('Received answer; setting remote description');
    await this.pc.setRemoteDescription(answer);
    console.debug('Set remote description');

    await Promise.all(
      this.queuedCandidates.splice(0).map(async (candidate) => {
        console.debug('Adding ICE candidate');
        await this.pc.addIceCandidate(candidate);
        console.debug('Added ICE candidate');
      }),
    );
    EventEmitter.emit('rtc-client-connected', true);
  }

  async startServer() {
    this.rtcType = 'server';
    this.socketId = SocketClient.socket.id;

    console.debug('Waiting for offer');
    const offer = await this.getOffer();

    console.debug('Received offer; setting remote description');
    await this.pc.setRemoteDescription(offer);

    console.debug('Set remote description; creating answer');
    const answer = await this.pc.createAnswer();

    console.debug('Created answer; setting local description');
    await this.pc.setLocalDescription(answer);

    answer.sdp = preferBitRate(answer.sdp, MAX_VIDEO_BITRATE, 'video');
    answer.sdp = preferBitRate(answer.sdp, MAX_AUDIO_BITRATE, 'audio');

    console.debug('Set local description; sending answer');
    this.sendMessage(answer);

    await Promise.all(
      this.queuedCandidates.splice(0).map(async (candidate) => {
        console.debug('Adding ICE candidate');
        await this.pc.addIceCandidate(candidate);
        console.debug('Added ICE candidate');
      }),
    );
  }

  getMessage(type) {
    return new Promise((resolve) => {
      SocketClient.socket.on('message', ({ roomName, ...message }) => {
        if (roomName === this.roomName) {
          if (message.type === type) {
            resolve(message);
          }
        }
      });
    });
  }

  async getAnswer() {
    const answer = await this.getMessage('answer');
    return new RTCSessionDescription(answer);
  }

  async getOffer() {
    const offer = await this.getMessage('offer');
    return new RTCSessionDescription(offer);
  }

  onCandidate(callback) {
    SocketClient.socket.on('candidate', ({ roomName, ...data }) => {
      if (roomName === this.roomName) {
        const candidate = new RTCIceCandidate(data);
        callback(candidate);
      }
    });
  }

  sendCandidate(candidate) {
    SocketClient.emit('candidate', { roomName: this.roomName, candidate });
  }

  sendMessage(message) {
    SocketClient.emit('message', { roomName: this.roomName, message });
  }

  _setBitrate(bitrate, type) {
    const sender = this.pc.getSenders().find((s) => s.track && s.track.kind === type);
    if (sender) {
      const parameters = sender.getParameters();
      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }
      parameters.encodings[0].maxBitrate = bitrate * 1000; // bitrate of 125 will set it to 125 Kbps
      sender.setParameters(parameters);
    }
  }

  stop() {
    SocketClient.socket.removeListener('message');
    SocketClient.socket.removeListener('candidate');
    SocketClient.leaveRoom(this.roomName);
    this.closing = true;
    this.pc.close();
  }
}
