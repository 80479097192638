import React, { useState, useEffect, useRef } from 'react';
import swal from '@sweetalert/with-react';
import { isIpad } from '../utils/browser-util';
import MediaDeviceUtil from '../utils/media-device-util';
import Button from '../common/Button';
import Select from '../common/Select';
import LightingIcon from '../assets/system-check-icons/icon-lighting.svg';
import VideoDelayIcon from '../assets/system-check-icons/icon-video-delay.svg';
import RTCClient from '../utils/rtc-client';
import Storage from '../utils/storage';
import VideoOverlay from '../common/VideoOverlay';
import Spinner from '../common/Spinner';
import CustomMessage from '../common/Messages/CustomMessage';
import EventEmitter from '../utils/event-emitter';

const localStorage = Storage.getLocalStorage();

export default function VideoCheck({ title, onComplete, onFailure, onUpdateProgress }) {
  const _videoRef = useRef(null);
  const _videoStreamRef = useRef(null);
  const _rtcClient = useRef(null);
  const _timer = useRef(null);

  const [validCamera, setValidCamera] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);

  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [mediaReady, setMediaReady] = useState(false);
  const [webrtcReady, setWebrtcReady] = useState(false);

  const _getVideoDevices = async () => {
    const availableDevices = await MediaDeviceUtil.getAvailableDevices(false);
    const _videoDevices = availableDevices.filter((device) => device.kind === 'videoinput');
    if (selectedVideoDevice) {
      const _selectedVideoDevice = _videoDevices.find((device) => device.deviceId === selectedVideoDevice.deviceId);
      if (!_selectedVideoDevice) {
        setSelectedVideoDevice(_videoDevices[0]);
      }
    }
    setVideoDevices(_videoDevices);
    return _videoDevices;
  };

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', _getVideoDevices);

    const _onWebrtcConnected = () => {
      setTimeout(() => {
        setWebrtcReady(true);
      }, 1000);
    };
    EventEmitter.on('rtc-client-connected', _onWebrtcConnected);

    (async () => {
      const _videoDevices = await _getVideoDevices();
      const storedVideoInput = localStorage.getItem('videoInput');

      const defaultVideoInput = _videoDevices.find((dev) => dev.isDefault === true) || _videoDevices[0];
      let _selectedVideoInput = defaultVideoInput;
      if (storedVideoInput && storedVideoInput.length !== 0) {
        try {
          const _videoInputObj = JSON.parse(storedVideoInput);
          const _videoInput = _videoDevices.find((v) => _videoInputObj && v.deviceId === _videoInputObj.deviceId);
          if (_videoInput) {
            _selectedVideoInput = _videoInput;
          }
        } catch (e) {
          // ignore
        }
      }

      setSelectedVideoDevice(_selectedVideoInput);
    })();

    return () => {
      EventEmitter.removeListener('rtc-client-connected', _onWebrtcConnected);
      navigator.mediaDevices.removeEventListener('devicechange', _getVideoDevices);

      if (_videoStreamRef.current) {
        _videoStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (_rtcClient.current) {
        _rtcClient.current.cleanup();
        _rtcClient.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (validCamera === true) {
      onUpdateProgress({ canSeeSelf: true, cameraName: selectedVideoDevice.label });
      _videoRef.current.srcObject = _videoStreamRef.current;
      _rtcClient.current = new RTCClient();
      _rtcClient.current.connect({
        name: 'System Check',
        location: 'System Check',
        uuid: sessionStorage.getItem('uuid'),
        stream: _videoStreamRef.current,
        isLoopbackTest: true,
      });

      _timer.current = setTimeout(async () => {
        _rtcClient.current.disconnect();
        await swal({
          buttons: {},
          closeOnClickOutside: false,
          closeOnEsc: false,
          className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
          content: (
            <CustomMessage
              title='Session Timeout'
              body={
                <div style={{ color: '#ddd', fontSize: 13 }}>
                  <p>Your session has timed out. Please refresh the page to try again.</p>
                </div>
              }
            />
          ),
        });
      }, 120000);
    } else if (validCamera !== null) {
      onUpdateProgress({ canSeeSelf: false });
      let device = '';
      let settings = '';
      if (validCamera === false) {
        device = 'camera';
        settings = 'video settings';
      }
      if (device !== '' && settings !== '') {
        onFailure && onFailure();
      }
    }
  }, [validCamera]);

  useEffect(() => {
    if (selectedVideoDevice) {
      (async () => {
        try {
          _videoRef.current.addEventListener('loadeddata', () => {
            setIsVideoLoaded(true);
          });

          if (_videoStreamRef.current) {
            _videoStreamRef.current.getTracks().forEach((track) => {
              track.stop();
            });
          }
          _videoStreamRef.current = await MediaDeviceUtil.getUserMedia({
            video: { deviceId: { exact: selectedVideoDevice.deviceId }, width: { ideal: 1280, max: 1920 }, height: { ideal: 720, max: 1080 } },
          });
          _videoStreamRef.current.getTracks().forEach((track) => {
            if (track.kind === 'video') {
              const mediaStream = new MediaStream();
              mediaStream.addTrack(track);
              _videoRef.current.srcObject = mediaStream;
            }
          });
          localStorage.setItem('videoInput', JSON.stringify(selectedVideoDevice));
        } catch (error) {
          console.log('applyVideoInputChange error', error);
        }
      })();
    }
  }, [selectedVideoDevice]);

  const _onPandoNetworkTestSuccess = () => {
    clearTimeout(_timer.current);
    onUpdateProgress({ canSeeLoopbackVideo: true });
    onComplete && onComplete();
  };

  const _onPandoNetworkTestFailure = () => {
    clearTimeout(_timer.current);
    onUpdateProgress({ canSeeLoopbackVideo: false });
    onFailure && onFailure();
  };

  return (
    <div style={{ width: '100%' }}>
      <h3>{validCamera === true ? 'Connecting to Pando' : title}</h3>
      <div>
        <table cellSpacing={3} style={{ width: '100%' }}>
          <tbody>
            {!validCamera && (
              <tr style={{ backgroundColor: 'rgb( 31, 43, 55)' }}>
                <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <div style={{ width: 320, marginBottom: 10 }}>
                        <Select
                          list={videoDevices}
                          listKey='deviceId'
                          listLabel='label'
                          onChange={(selected) => {
                            localStorage.setItem('videoInput', JSON.stringify(selected));
                            setSelectedVideoDevice(selected);
                          }}
                          selected={selectedVideoDevice && selectedVideoDevice.deviceId}
                          currentOption={selectedVideoDevice && selectedVideoDevice.label}
                          containerStyle={{ width: '100%' }}
                          small
                        />
                      </div>
                      <div style={{ textAlign: 'center', position: 'relative' }}>
                        {!isVideoLoaded && (
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: 320,
                              height: 180,
                              backgroundColor: '#1E2A37',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Spinner />
                          </div>
                        )}
                        <video ref={_videoRef} style={{ width: 320, height: 180, backgroundColor: 'black' }} autoPlay playsInline muted />
                      </div>
                    </div>
                    <div style={{ margin: '0 30px' }}>
                      <p>
                        Please select your desired camera using the dropdown menu.
                        <br />
                        <br />
                        Can you see yourself?
                      </p>
                      <div>
                        <Button
                          type={validCamera ? 'primary' : 'secondary'}
                          text='Yes'
                          disabled={!isVideoLoaded}
                          containerStyle={{ padding: '2px 40px' }}
                          onClick={() => setValidCamera(true)}
                        />
                        <Button type='secondary' text='No' containerStyle={{ padding: '2px 40px' }} onClick={() => setValidCamera(false)} />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {validCamera && (
              <tr style={{ backgroundColor: 'rgb( 31, 43, 55)' }}>
                <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 20,
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div
                        style={{
                          flex: 1,
                          margin: '0 20px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h3>How you will see your self</h3>
                        </div>
                        <div style={{ width: 320 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              fontSize: 12,
                              padding: 5,
                              minHeight: 80,
                              backgroundColor: '#314253',
                            }}
                          >
                            <img src={LightingIcon} alt='' height={40} />
                            <p style={{ margin: 5 }}>
                              Make sure you are centered within the brackets, lighting is good - things are not dark, no distracting background etc.
                            </p>
                          </div>
                          <div style={{ textAlign: 'center', position: 'relative' }}>
                            <VideoOverlay paddingBottom={18} />
                            <video ref={_videoRef} style={{ width: 320, height: 180, backgroundColor: 'black' }} autoPlay playsInline muted />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          margin: '0 20px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h3>How others will see you</h3>
                        </div>
                        <div style={{ width: 320 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              fontSize: 12,
                              padding: 5,
                              minHeight: 80,
                              backgroundColor: '#314253',
                            }}
                          >
                            <img src={VideoDelayIcon} alt='' height={50} />
                            <p style={{ margin: 5 }}>It is normal for there to be a small delay between how you will see yourself, and what others will see.</p>
                          </div>
                          <div style={{ textAlign: 'center', position: 'relative' }}>
                            {!mediaReady && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: 320,
                                  height: 180,
                                  backgroundColor: '#1E2A37',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Spinner />
                              </div>
                            )}
                            <audio id='remote-audio' muted={true} />
                            <video
                              id='remote-video'
                              style={{ opacity: 1, width: 320, height: 180, backgroundColor: 'black' }}
                              onLoadedData={() => setMediaReady(true)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: '0 30px', textAlign: 'center' }}>
                      <p>Can you see yourself in both video windows above?</p>
                      <div>
                        <Button
                          type={'secondary'}
                          text='Yes'
                          containerStyle={{ padding: '2px 40px' }}
                          onClick={_onPandoNetworkTestSuccess}
                          disabled={!webrtcReady}
                        />
                        <Button type='secondary' text='No' containerStyle={{ padding: '2px 40px' }} onClick={_onPandoNetworkTestFailure} />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
