import React, { useEffect, useState } from 'react';
import SpeedTest from '../common/SpeedTest';
import TimeUtil from '../utils/time-util';

export default function BandwidthTest({ title, onComplete, onUpdateProgress }) {
  const [validSpeedConnection, setValidSpeedConnection] = useState(null);

  const _onSpeedTestSuccess = (bandwidth) => {
    onUpdateProgress({ bandwidth });
    setValidSpeedConnection(true);
  };

  useEffect(() => {
    (async () => {
      if (validSpeedConnection === true) {
        await TimeUtil.sleep(3000);
        onComplete && onComplete();
      } else if (validSpeedConnection === false) {
        onComplete && onComplete(false);
      }
    })();
  }, [validSpeedConnection]);

  return (
    <div style={{ width: '100%' }}>
      <h3>{title}</h3>
      <div>
        <table cellSpacing={3} style={{ width: '100%' }}>
          <tbody>
            <tr style={{ backgroundColor: 'rgb( 31, 43, 55)' }}>
              <td style={{ verticalAlign: 'top', padding: 10, fontSize: 12 }}>
                <SpeedTest
                  isOffWallParticipant={false}
                  hideContinueButton={true}
                  minBandwidth={1.5}
                  speedTests={['2mb', '5mb']}
                  onSuccess={_onSpeedTestSuccess}
                  onFailure={() => setValidSpeedConnection(false)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
