import React from 'react';
import PropTypes from 'prop-types';
import './Input.css';

function Input(props) {
  const { label, id, style, onChange, name, type, value, stacked, labelStyle, hasError } = props;
  const input = (
    <input id={id} name={name} type={type} value={value} onChange={onChange} style={style} autoComplete='off' className={hasError ? 'error' : ''} />
  );
  if (stacked) {
    return (
      <div className='stacked-input'>
        <label htmlFor={id}>{label}</label>
        {input}
      </div>
    );
  } else {
    return (
      <div className='input-wrapper'>
        <label htmlFor={id} className='input-label'>
          <span style={labelStyle}>{label}</span>
          {input}
        </label>
      </div>
    );
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  stacked: PropTypes.bool,
};

Input.defaultProps = {
  value: '',
  id: '',
  label: '',
};

export default Input;
